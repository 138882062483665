<template>
  <div class="overview" v-loading="loading">
    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <el-tab-pane v-for="(item,index) in typeList" :key="index" :label="item.type" :name="item._id"></el-tab-pane>
    </el-tabs>
    <div class="total">
      <div class="totalItem">
        <span class="label">总重量：</span>
        <span class="value">{{allOutbound}}</span>
      </div>
      <div class="totalItem">
        <span class="label">总件数：</span>
        <span class="value">{{allOutboundNum}}</span>
      </div>
      <div class="totalItem">
        <span class="label">总金额：</span>
        <span class="value">{{allOutboundMoney}}</span>
      </div>
    </div>
    <el-table ref="table"  :height="tableH" class="table"  :data="currentData">
      <el-table-column label="规格" prop="specs"></el-table-column>
      <el-table-column label="剩余库存" prop="totalStock"></el-table-column>
      <el-table-column label="剩余件数" prop="totalNumber"></el-table-column>
      <el-table-column label="价值金额" prop="totalMoney"></el-table-column>
    </el-table>

  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
// import dayjs from 'dayjs'
import { getInventoryCountApi, getTypeListApi } from './fetch'
export default {
  setup () {
    const table = ref(null)
    const state = reactive({
      currentData: null,
      activeName: '',
      typeList: [],
      allOutbound: undefined,
      allOutboundNum: undefined,
      allOutboundMoney: undefined,
      loading: false,
      tableH: 0
    })

    onMounted(async () => {
      const res = await getTypeListApi({ page: 1, rows: 9999 })
      state.typeList = res.data
      state.activeName = res.data[0]?._id
      await getSurplus()
      getHeight()
    })

    const getHeight = () => {
      state.tableH = table.value.$el.offsetHeight
      console.log(state.tableH)
    }

    window.onresize = () => {
      getHeight()
    }

    const getSurplus = async (id) => {
      try {
        state.loading = true
        state.currentData = []
        const res = await getInventoryCountApi({
          type: id || state.typeList[0]._id
        })
        state.allOutbound = res.allOutbound
        state.allOutboundNum = res.allOutboundNum
        state.allOutboundMoney = res.allOutboundMoney
        state.currentData = res.list
      } catch (e) {

      } finally {
        state.loading = false
      }
    }

    const handleTabClick = async (id) => {
      getSurplus(id.paneName)
    }

    return {
      ...toRefs(state),
      handleTabClick,
      table
    }
  }
}
</script>

<style lang="stylus" scoped>
.overview
  height 100%
  display flex
  flex-direction: column
  .table
    height 0
    flex 1
  .total
    padding-right 300px
    display flex
    justify-content space-between
    .value
      font-weight bold
</style>
