import Request from '@/utils/request'

export function getInventoryCountApi (data) {
  return Request({
    url: '/inventory/count',
    params: data
  })
}

export function getTypeListApi (data) {
  return Request({
    url: '/type',
    params: data
  })
}
